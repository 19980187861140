import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const resources = {
  en: {
    translation: {
      menu_home: "Home",
      menu_about_us: "About Us",
      menu_services: "Services",
      menu_contact_us: "Contact Us",
      home_desciption:
        "Bramer Tours USA is a tour and events operator located in Las Vegas.",
      home_desciption_2:
        "We offer services for individual passengers and leisure groups, as well as specialized services for corporate event groups, incentive trips, trade shows and congresses production.",
      home_desciption_3:
        "Our highly specialized team is prepared to ensure success and satisfaction in all stages of your event or trip, from planning to execution, providing unique and memorable experiences for our clients.",
      about_us_text_1:
        "Founded in 2002, Bramer Tours started its activities with inbound tourism in the United States. Over time, we expanded, evolving into a tour and events operator. In 2018, we achieved our own fleet.",
      about_us_text_2:
        "With 24 years of experience in the Brazilian Market, Latin America, Mexico, and Europe, we demonstrate our commitment and expertise in providing high-quality services, delighting clients in different regions of the world.",
      about_us_text_3:
        "Our team is always in search of innovation and the best available resources, collaborating with our partners to ensure excellence and quality in all aspects.",
      service_1_title: "Tour Operator and Leisure Groups",
      service_1_description:
        "We offer a range of services during the trips of individual passengers (FIT) and leisure groups. From receptions, transfers, accommodation, tours to coordination of local activities, ensuring a complete and personalized experience for each client.",
      service_2_title: "Corporate Events and Incentive Trips",
      service_2_description:
        "Specialized in the planning and execution of tailor-made corporate events and incentive trips. Our team develops exclusive itineraries that include a variety of activities, restaurants, and local experiences throughout the American territory. From strategic meetings to team building events and leisure moments, we take care of every detail to ensure a memorable and impactful experience for our clients and their employees.",
      service_3_title: "Congress & Trade Show Production",
      service_3_description:
        "Bramer Tours USA's production team provides comprehensive services and support for congresses and trade show exhibits and booths. From initial planning to on-site support, we handle every aspect, including set design, assembly, supplier management, and gift coordination. Our goal is to guarantee our clients' success by delivering a memorable experience for visitors.",
      service_1_subtitle_1: "Las Vegas",
      service_1_subtitle_2: "Los Angeles",
      service_1_subtitle_3: "San Francisco",
      service_1_subtitle_1_description:
        "Las Vegas is known as the entertainment capital, with a huge amount of exciting options for visitors. Here's an overview of the main tourist attractions and services available in Las Vegas.",
      service_1_subtitle_1_description_1:
        "Airport/Hotel/Airport Transfer: We offer safe and luxury shuttle services between the airport and the hotel, ensuring a smooth arrival and departure for visitors.",
      service_1_subtitle_1_description_2:
        "Shopping Tour: Explore Las Vegas' various malls and outlets, where you'll find a wide variety of renowned brand stores with incredible discounts.",
      service_1_subtitle_1_description_3:
        "'Night Las Vegas' City Tour: Discover the dazzling Las Vegas at night, with its famous neon signs, dancing fountains, and a vibrant atmosphere that turns the city into a spectacle of lights and colours.",
      service_1_subtitle_1_description_4:
        "Grand Canyon 'West Rim': Take an exciting excursion to the Grand Canyon, one of the world's most breathtaking natural wonders, and enjoy stunning panoramic views, hikes, and optional activities like helicopter rides over the canyon.",
      service_1_subtitle_1_description_5:
        "Red Rock Canyon: Explore the natural beauty of Red Rock Canyon, with its impressive rock formations, hiking trails, and breathtaking views, all just a short drive from Las Vegas.",
      service_1_subtitle_1_description_6:
        "Hoover Dam: Visit the iconic Hoover Dam, one of the world's largest engineering structures, and learn about its fascinating history on a guided tour.",
      service_1_subtitle_1_description_7:
        "Helicopter Tours: Experience Las Vegas from a unique perspective with an unforgettable helicopter ride over the Strip, the Grand Canyon, or other nearby attractions.",
      service_1_subtitle_1_description_8:
        "Route 66: Take a nostalgic journey along the historic Route 66, exploring the scene of countless movie stopping by Kingman city and at the Route 66 Museum.",
      service_1_subtitle_1_description_9:
        "Mount Charleston: Escape the desert heat and enjoy a relaxing day in the Mount Charleston with hiking trails, picnic areas, and amazing panoramic views. In the winter you can also enjoy skying.",
      service_1_subtitle_1_description_10:
        "Cirque du Soleil: Watch an unforgettable Cirque du Soleil show, known for its stunning acrobatic performances, elaborate choreography, and visually stunning productions.",
      service_1_subtitle_1_description_11:
        "Sphere: Experience Las Vegas' latest entertainment attraction, the Sphere, an innovative venue offering state-of-the-art immersive experiences, live music shows, special events, and more.",
      service_1_subtitle_1_description_12:
        "Others: Additionally, we offer a variety of other activities and customized tours to cater to the interests and individual preferences of visitors, including gourmet dining, exclusive gaming experiences, adventure tours, and more.",
      service_1_subtitle_2_description:
        "Los Angeles, known as the 'City of Angels,' is a diverse and vibrant metropolis, filled with exciting attractions for visitors. Here's an overview of the main tourist attractions and services available in Los Angeles.",
      service_1_subtitle_2_description_1:
        "Airport Service: We offer convenient and reliable shuttle services between the airport and the hotel, ensuring a smooth arrival and departure for visitors.",
      service_1_subtitle_2_description_2:
        "Shopping Tour: Explore Los Angeles' famous malls and boutiques, where you'll find a wide selection of designer stores, exclusive boutiques, and unique street markets.",
      service_1_subtitle_2_description_3:
        "Los Angeles and Hollywood City Tour: Discover the iconic sights of Los Angeles and Hollywood, including the Hollywood Sign, Walk of Fame, TCL Chinese Theatre, Santa Monica Pier, and more.",
      service_1_subtitle_2_description_4:
        "Santa Monica Beach Tour: Enjoy a relaxing day at the stunning Santa Monica Beach, where you can stroll along the famous Santa Monica Pier, shop at the Third Street Promenade, or simply relax in the sun.",
      service_1_subtitle_2_description_5:
        "Santa Barbara Day Trip: Take a scenic trip to the charming coastal town of Santa Barbara, known for its white sand beaches, Spanish architecture, and picturesque vineyards.",
      service_1_subtitle_2_description_6:
        "Disney, Universal, and Warner Parks Shuttle: We offer convenient transportation to the region's most popular theme parks, including Disneyland, Universal Studios Hollywood, and Warner Bros. Studio Tour.",
      service_1_subtitle_2_description_7:
        "Others: Additionally, we can arrange a variety of other activities and customized tours, such as studio visits, wine tastings at local wineries, city bike tours, and more.",
      service_1_subtitle_3_description:
        "San Francisco, known for its unique atmosphere, stunning landscapes, and diverse culture, is a city that offers a wide variety of experiences for visitors. Here's an overview of the main tourist attractions and services available in San Francisco.",
      service_1_subtitle_3_description_1:
        "Airport Service: We offer reliable and comfortable shuttle services between the airport and the hotel, ensuring a stress-free arrival and departure for visitors.",
      service_1_subtitle_3_description_2:
        "Shopping Tour: Explore the diverse shopping options in San Francisco, including exclusive boutiques, designer stores, and quaint street markets.",
      service_1_subtitle_3_description_3:
        "Union Square and Fisherman's Wharf City Tour: Discover San Francisco's most famous sights, such as Union Square, known for its luxury shops, and Fisherman's Wharf, with its lively atmosphere and fresh seafood restaurants.",
      service_1_subtitle_3_description_4:
        "Golden Gate Bridge and Sausalito City Tour: Marvel at the iconic Golden Gate Bridge and enjoy spectacular panoramic views of the city. Then, take a visit to the charming coastal town of Sausalito, with its art galleries, charming shops, and waterfront restaurants.",
      service_1_subtitle_3_description_5:
        "Alcatraz: Take a tour of the famous Alcatraz prison, situated on an island in San Francisco Bay. Explore the historic cells, learn about the prison's fascinating history, and enjoy stunning views of the city.",
      service_1_subtitle_3_description_6:
        "Napa Valley: Enjoy a relaxing trip to Napa Valley, one of the world's most famous wine regions. Taste wines at award-winning wineries, explore beautiful vineyards, and enjoy gourmet meals at local restaurants.",
      service_1_subtitle_3_description_7:
        "Others: Additionally, we can arrange a variety of other activities and customized tours, such as biking tours across the Golden Gate Bridge, visits to art and science museums, cruises on San Francisco Bay, and more.",
      service_2_subtitle_1: "Corporate Events",
      service_2_subtitle_1_description_1:
        "With a comprehensive and client-focused approach, we ensure that every corporate event is a success, leaving a lasting impression on participants and achieving the company's business objectives.",
      service_2_subtitle_1_description_2:
        "We take care of all logistical aspects, such as venue booking, transportation, accommodation and catering, to ensure a smooth experience for participants.",
      service_2_subtitle_2: "Incentive Travel",
      service_2_subtitle_2_description_1:
        "We create customized trips tailored to each client's interests, objectives, and budget. Our exclusive destinations and authentic experiences go beyond conventional tourism, providing unforgettable moments. ",
      service_2_subtitle_2_description_2:
        "We meticulously manage every logistical detail and service to ensure flawless execution. Our unwavering attention to detail guarantees that every aspect is delivered with utmost quality and excellence ",
      service_3_subtitle_1: "Production",
      service_3_subtitle_1_description:
        "Bramer Tours USA's production team delivers impeccable solutions for trade show booths and exhibits, ensuring every detail is meticulously managed. From concept to execution, we guarantee a standout presence that captivates audiences.",
      service_3_subtitle_2: "Coordination",
      service_3_subtitle_2_description:
        "Elevate your next congress or trade show with Bramer Tours USA's expert coordination services. Our dedicated team ensures seamless planning and execution for your congress or trade show, guaranteeing a standout experience that exceeds expectations and leaves a lasting impression.",
      contact_us_title:
        "We look forward to hearing from you! Please contact us to discuss how we can meet your needs. We are here to provide exceptional and personalized solutions for you. We look forward to your contact!",
      contact_us_label_full_name: "Full Name",
      contact_us_label_email: "Email",
      contact_us_label_phone: "Phone",
      contact_us_label_company: "Company",
      contact_us_label_subject: "Subject",
      contact_us_label_subject_selector_single: "Individual (FIT)",
      contact_us_label_subject_selector_group: "Leisure Group",
      contact_us_label_subject_selector_event: "Corporate Event",
      contact_us_label_subject_selector_travel: "Incentive Travel",
      contact_us_label_subject_selector_marketing:
        "Trade Shows, Congresses, and Stands",
      contact_us_label_message: "Message",
      contact_us_label_message_agreement:
        "I agree to provide my personal data (name, email, phone, etc.) exclusively for the purpose of contact and communication related to the services offered. I understand that my data will not be shared with third parties and will only be used as necessary for this specific purpose.",
      contact_us_button_submit: "Submit",
      contact_us_error_full_name: "Please fill in your full name",
      contact_us_error_email: "Please enter your email address",
      contact_us_error_company: "Please enter the company name",
      contact_us_error_subject: "Please select the subject of interest",
      contact_us_error_message: "Please write your message",
      contact_us_error_agree: "You must agree to the terms to send the message",
      contact_message_successful: "Your message has been submitted",
      contact_message_successful_error: "An error occurred. Please try again later",
      privacy_policy: "Privacy Policy",
      privacy_policy_description:
        "This Privacy Policy describes how we collect, use, and share your personal information when you use our services. By using our services, you agree to the collection and use of your information in accordance with this policy.",
      privacy_policy_information_collection: "Information Collection",
      privacy_policy_information_collection_description:
        "We may collect personal information that you provide to us directly when using our services, such as your name, email address, phone number, and payment information.",
      privacy_policy_use_information: "Use of Information",
      privacy_policy_use_information_description:
        "We use your personal information to provide, maintain, and improve our services, to process your transactions, respond to support requests, and to send communications related to our services.",
      privacy_policy_information_sharing: "Information Sharing",
      privacy_policy_information_sharing_description:
        "We do not share your personal information with third parties except when necessary to provide our services, comply with legal obligations, or protect our rights.",
      privacy_policy_security: "Security",
      privacy_policy_security_description:
        "We implement security measures to protect your information against unauthorized access, alteration, disclosure, or destruction.",
      privacy_policy_changes_to_this_policy: "Changes to this Policy",
      privacy_policy_changes_to_this_policy_description:
        "This Privacy Policy is subject to change. Any changes will be posted on this page and will take effect immediately upon publication.",
      privacy_policy_contact_us: "Contact Us",
      privacy_policy_doubt:
        "If you have any questions about this Privacy Policy, please contact us.",
      privacy_policy_last_update:
        "This Privacy Policy was last updated on 04/13/24.",
    },
  },
  es: {
    translation: {
      menu_home: "Inicio",
      menu_about_us: "Sobre Nosotros",
      menu_services: "Servicios",
      menu_contact_us: "Contáctanos",
      home_desciption:
        "Bramer Tours USA es un operador turístico y de eventos ubicado en Las Vegas.",
      home_desciption_2:
        "Ofrecemos servicios para pasajeros individuales y grupos de ocio, así como servicios especializados para grupos de eventos corporativos, viajes de incentivo, producción de ferias y stands.",
      home_desciption_3:
        "Nuestro equipo altamente especializado está preparado para garantizar el éxito y la satisfacción en todas las etapas de su evento o viaje, desde la planificación hasta la ejecución, brindando experiencias únicas y memorables para nuestros clientes.",
      about_us_text_1:
        "Fundada en 2002, Bramer Tours comenzó sus actividades con el turismo receptivo en los Estados Unidos. Con el tiempo, nos expandimos, evolucionando hacia un operador turístico y de eventos. En 2018, logramos nuestra propia flota.",
      about_us_text_2:
        "Con 24 años de experiencia en el Mercado Brasileño, América Latina, México y Europa, demostramos nuestro compromiso y experiencia en proporcionar servicios de alta calidad, deleitando a clientes en diferentes regiones del mundo.",
      about_us_text_3:
        "Nuestro equipo está siempre en busca de innovación y de los mejores recursos disponibles, colaborando con nuestros socios para garantizar la excelencia y calidad en todos los aspectos.",
      service_1_title: "Operadora de Turismo y Grupos de Ocio",
      service_1_description:
        "Ofrecemos una gama de servicios durante los viajes de pasajeros individuales (FIT) y grupos de ocio. Desde receptivos, traslados, alojamiento, excursiones hasta la coordinación de actividades locales, garantizando una experiencia completa y personalizada para cada cliente.",
      service_2_title: "Eventos Corporativos y Viajes de Incentivo",
      service_2_description:
        "Especializada en la planificación y ejecución de eventos corporativos y viajes de incentivo a medida. Nuestro equipo desarrolla itinerarios exclusivos que incluyen una variedad de actividades, restaurantes y experiencias locales en todo el territorio americano. Desde reuniones estratégicas hasta eventos de team building y momentos de ocio, cuidamos de todos los detalles para garantizar una experiencia memorable e impactante para nuestros clientes y sus colaboradores.",
      service_3_title: "Ferias, Congresos y Stands",
      service_3_description:
        "El equipo de producción de Bramer Tours USA ofrece un servicio completo para ferias, congresos y stands. Desde la planificación inicial hasta el soporte durante el evento, coordinan todos los aspectos, incluyendo escenografía, montaje, gestión de proveedores y gestión de regalos. Su objetivo es garantizar que la participación del cliente sea un éxito, proporcionando una experiencia memorable para los visitantes.",
      service_1_subtitle_1: "Las Vegas",
      service_1_subtitle_2: "Los Angeles",
      service_1_subtitle_3: "San Francisco",
      service_1_subtitle_1_description:
        "Las Vegas es conocida como la ciudad del entretenimiento, con una infinidad de opciones emocionantes para los visitantes. Aquí está una visión general de los principales puntos turísticos y servicios disponibles en Las Vegas.",
      service_1_subtitle_1_description_1:
        "Traslado Aeropuerto/Hotel/Aeropuerto: Ofrecemos servicios de traslado seguros y cómodos entre el aeropuerto y el hotel, garantizando una llegada y salida tranquila para los visitantes.",
      service_1_subtitle_1_description_2:
        "Tour de Compras: Explore los diversos centros comerciales y outlets de Las Vegas, donde encontrará una amplia variedad de tiendas de marcas reconocidas con descuentos increíbles.",
      service_1_subtitle_1_description_3:
        "City Tour 'Night Las Vegas': Descubra la deslumbrante Las Vegas de noche, con sus famosos letreros luminosos, fuentes bailarinas y una atmósfera vibrante que convierte a la ciudad en un espectáculo de luces y colores.",
      service_1_subtitle_1_description_4:
        "Grand Canyon 'West Rim': Realice una emocionante excursión al Gran Cañón, una de las maravillas naturales más impresionantes del mundo, y disfrute de impresionantes vistas panorámicas, caminatas y actividades opcionales como paseos en helicóptero sobre el cañón.",
      service_1_subtitle_1_description_5:
        "Red Rock Canyon: Explore la belleza natural del Red Rock Canyon, con sus impresionantes formaciones rocosas, senderos para caminatas y vistas impresionantes, todo a poca distancia de Las Vegas.",
      service_1_subtitle_1_description_6:
        "Hoover Dam: Visite la icónica Hoover Dam, una de las estructuras de ingeniería más grandes del mundo, y aprenda sobre su fascinante historia en un recorrido guiado.",
      service_1_subtitle_1_description_7:
        "Tours en Helicóptero: Experimente Las Vegas desde una perspectiva única con un emocionante paseo en helicóptero sobre la Strip, el Gran Cañón u otras atracciones cercanas.",
      service_1_subtitle_1_description_8:
        "Ruta 66: Realice un viaje nostálgico por la histórica Ruta 66, explorando sus pintorescas ciudades, puntos de interés y restaurantes clásicos.",
      service_1_subtitle_1_description_9:
        "Monte Charleston: Escápese del calor del desierto y disfrute de un día relajante en las montañas de Monte Charleston, con senderos para caminatas, áreas para picnic y vistas panorámicas increíbles.",
      service_1_subtitle_1_description_10:
        "Cirque du Soleil: Disfrute de un espectáculo inolvidable del Cirque du Soleil, conocido por sus impresionantes actuaciones acrobáticas, coreografías elaboradas y producciones visualmente deslumbrantes.",
      service_1_subtitle_1_description_11:
        "Sphere: Experimente la última atracción de entretenimiento de Las Vegas, la Sphere, un lugar innovador que ofrece experiencias inmersivas de última generación, conciertos de música en vivo, eventos especiales y mucho más.",
      service_1_subtitle_1_description_12:
        "Otros: Además, ofrecemos una variedad de otras actividades y excursiones personalizadas para satisfacer los intereses y preferencias individuales de los visitantes, incluyendo cenas gourmet, experiencias de juego exclusivas, tours de aventura y más.",
      service_1_subtitle_2_description:
        "Los Ángeles, conocida como la 'Ciudad de los Ángeles', es una metrópoli diversa y vibrante, llena de emocionantes atracciones para los visitantes. Aquí está una visión general de los principales puntos turísticos y servicios disponibles en Los Ángeles.",
      service_1_subtitle_2_description_1:
        "Traslado Aeropuerto/Hotel/Aeropuerto: Ofrecemos servicios de traslado convenientes y confiables entre el aeropuerto y el hotel, garantizando una llegada y salida sin problemas para los visitantes.",
      service_1_subtitle_2_description_2:
        "Tour de Compras: Explore los famosos centros comerciales y boutiques de Los Ángeles, donde encontrará una amplia selección de tiendas de diseño, boutiques exclusivas y mercados callejeros únicos.",
      service_1_subtitle_2_description_3:
        "City Tour de Los Ángeles y Hollywood: Descubra los lugares icónicos de Los Ángeles y Hollywood, incluyendo el letrero de Hollywood, el Paseo de la Fama, el Teatro Chino TCL, el Muelle de Santa Mónica y más.",
      service_1_subtitle_2_description_4:
        "Tour de Playa de Santa Mónica: Disfrute de un día relajante en la impresionante playa de Santa Mónica, donde puede pasear por el famoso Muelle de Santa Mónica, comprar en la Tercera Calle Promenade o simplemente relajarse al sol.",
      service_1_subtitle_2_description_5:
        "Excursión a Santa Bárbara: Realice un viaje escénico a la encantadora ciudad costera de Santa Bárbara, conocida por sus playas de arena blanca, arquitectura española y pintorescos viñedos.",
      service_1_subtitle_2_description_6:
        "Traslado a Parques Disney, Universal y Warner: Ofrecemos transporte conveniente a los parques temáticos más populares de la región, incluyendo Disneyland, Universal Studios Hollywood y Warner Bros. Studio Tour.",
      service_1_subtitle_2_description_7:
        "Otros: Además, podemos organizar una variedad de otras actividades y excursiones personalizadas, como visitas a estudios de cine, catas de vinos en bodegas locales, tours en bicicleta por la ciudad y más.",
      service_1_subtitle_3_description:
        "San Francisco, conocida por su atmósfera única, paisajes impresionantes y cultura diversa, es una ciudad que ofrece una amplia variedad de experiencias para los visitantes. Aquí está una visión general de los principales puntos turísticos y servicios disponibles en San Francisco.",
      service_1_subtitle_3_description_1:
        "Traslado Aeropuerto/Hotel/Aeropuerto: Ofrecemos servicios de traslado confiables y cómodos entre el aeropuerto y el hotel, garantizando una llegada y salida sin estrés para los visitantes.",
      service_1_subtitle_3_description_2:
        "Tour de Compras: Explore las diversas opciones de compras en San Francisco, incluyendo boutiques exclusivas, tiendas de diseño y pintorescos mercados callejeros.",
      service_1_subtitle_3_description_3:
        "City Tour 'Union Square y Fisherman's Wharf': Descubra los lugares más famosos de San Francisco, como Union Square, conocido por sus tiendas de lujo, y Fisherman's Wharf, con su animada atmósfera y restaurantes de mariscos frescos.",
      service_1_subtitle_3_description_4:
        "City Tour 'Golden Gate Bridge y Sausalito': Maravíllese con el icónico Puente Golden Gate y disfrute de espectaculares vistas panorámicas de la ciudad. Luego, visite la encantadora ciudad costera de Sausalito, con sus galerías de arte, tiendas encantadoras y restaurantes frente al mar.",
      service_1_subtitle_3_description_5:
        "Alcatraz: Realice una visita a la famosa prisión de Alcatraz, situada en una isla en la bahía de San Francisco. Explore las celdas históricas, aprenda sobre la fascinante historia de la prisión y disfrute de impresionantes vistas de la ciudad.",
      service_1_subtitle_3_description_6:
        "Valle de Napa: Disfrute de un viaje relajante al Valle de Napa, una de las regiones vinícolas más famosas del mundo. Pruebe vinos en bodegas premiadas, explore hermosos viñedos y disfrute de comidas gourmet en restaurantes locales.",
      service_1_subtitle_3_description_7:
        "Otros: Además, podemos organizar una variedad de otras actividades y excursiones personalizadas, como recorridos en bicicleta por el Puente Golden Gate, visitas a museos de arte y ciencia, cruceros por la bahía de San Francisco y más.",
      service_2_subtitle_1: "Eventos Corporativos",
      service_2_subtitle_1_description_1:
        "Con un enfoque integral y centrado en el cliente, garantizamos que cada evento corporativo sea un éxito, dejando una impresión duradera en los participantes y alcanzando los objetivos comerciales de la empresa.",
      service_2_subtitle_1_description_2:
        "Nos encargamos de todos los aspectos logísticos, como la reserva de lugares, el transporte, el alojamiento y el catering, para garantizar una experiencia tranquila para los participantes.",
      service_2_subtitle_2: "Viajes de Incentivo",
      service_2_subtitle_2_description_1:
        "Creamos viajes a medida, teniendo en cuenta los intereses, objetivos y presupuesto de cada cliente.",
      service_2_subtitle_2_description_2:
        "Ofrecemos acceso a destinos exclusivos y experiencias auténticas que van más allá del turismo convencional, proporcionando momentos memorables para los participantes.",
      service_3_subtitle_1: "Producción",
      service_3_subtitle_1_description:
        "El equipo de producción de Bramer Tours USA ofrece soluciones impecables para stands y exposiciones en ferias comerciales, asegurando que cada detalle sea gestionado meticulosamente. Desde el concepto hasta la ejecución, garantizamos una presencia destacada que cautiva al público.",
      service_3_subtitle_2: "Coordinación",
      service_3_subtitle_2_description:
        "Eleva tu próximo congreso o feria comercial con los servicios de coordinación expertos de Bramer Tours USA. Nuestro equipo dedicado asegura una planificación y ejecución impecables para tu congreso o feria comercial, garantizando una experiencia destacada que supera las expectativas y deja una impresión duradera.",
      contact_us_title:
        "¡Esperamos escuchar de usted! Por favor, contáctenos para discutir cómo podemos satisfacer sus necesidades. Estamos aquí para ofrecer soluciones excepcionales y personalizadas para usted. ¡Esperamos su contacto!",
      contact_us_label_full_name: "Nombre completo",
      contact_us_label_email: "Correo electrónico",
      contact_us_label_phone: "Teléfono",
      contact_us_label_company: "Empresa",
      contact_us_label_subject: "Asunto",
      contact_us_label_subject_selector_single: "Individual (FIT)",
      contact_us_label_subject_selector_group: "Grupo de ocio",
      contact_us_label_subject_selector_event: "Evento corporativo",
      contact_us_label_subject_selector_travel: "Viaje de incentivo",
      contact_us_label_subject_selector_marketing: "Ferias, Congresos y Stands",
      contact_us_label_message: "Mensaje",
      contact_us_label_message_agreement:
        "Acepto proporcionar mis datos personales (nombre, correo electrónico, teléfono, etc.) exclusivamente con el fin de contacto y comunicación relacionada con los servicios ofrecidos. Entiendo que mis datos no serán compartidos con terceros y solo se utilizarán según sea necesario para este propósito específico.",
      contact_us_button_submit: "Enviar",
      contact_us_error_full_name: "Por favor, complete su nombre completo",
      contact_us_error_email:
        "Por favor, ingrese su dirección de correo electrónico",
      contact_us_error_company: "Por favor, ingrese el nombre de su empresa",
      contact_us_error_subject: "Por favor, seleccione el tema de interés",
      contact_us_error_message: "Por favor, escriba su mensaje",
      contact_us_error_agree:
        "Debe aceptar los términos para enviar el mensaje",
        contact_message_successful: "Mensaje enviado con éxito",
        contact_message_successful_error: "Ocurrió un error. Por favor, inténtelo de nuevo más tarde",
      privacy_policy: "Política de privacidad",
      privacy_policy_description:
        "Esta Política de Privacidad describe cómo recopilamos, usamos y compartimos su información personal cuando utiliza nuestros servicios. Al utilizar nuestros servicios, usted acepta la recopilación y el uso de su información de acuerdo con esta política.",
      privacy_policy_information_collection: "Recopilación de Información",
      privacy_policy_information_collection_description:
        "Podemos recopilar información personal que nos proporcione directamente al utilizar nuestros servicios, como su nombre, dirección de correo electrónico, número de teléfono e información de pago.",
      privacy_policy_use_information: "Uso de la Información",
      privacy_policy_use_information_description:
        "Utilizamos su información personal para proporcionar, mantener y mejorar nuestros servicios, para procesar sus transacciones, responder a solicitudes de soporte y enviar comunicaciones relacionadas con nuestros servicios.",
      privacy_policy_information_sharing: "Compartir Información",
      privacy_policy_information_sharing_description:
        "No compartimos su información personal con terceros, excepto cuando sea necesario para proporcionar nuestros servicios, cumplir con obligaciones legales o proteger nuestros derechos.",
      privacy_policy_security: "Seguridad",
      privacy_policy_security_description:
        "Implementamos medidas de seguridad para proteger su información contra el acceso no autorizado, la alteración, divulgación o destrucción.",
      privacy_policy_changes_to_this_policy: "Cambios en esta Política",
      privacy_policy_changes_to_this_policy_description:
        "Esta Política de Privacidad está sujeta a cambios. Cualquier cambio será publicado en esta página y entrará en vigor inmediatamente después de su publicación.",
      privacy_policy_contact_us: "Contáctenos",
      privacy_policy_doubt:
        "Si tiene alguna pregunta sobre esta Política de Privacidad, contáctenos.",
      privacy_policy_last_update:
        "Esta Política de Privacidad se actualizó el 13/04/24.",
    },
  },
  pt: {
    translation: {
      menu_home: "Início",
      menu_about_us: "Sobre nós",
      menu_services: "Serviços",
      menu_contact_us: "Entre em contato",
      home_desciption:
        "A Bramer Tours USA é uma operadora de turismo e eventos localizada em Las Vegas.",
      home_desciption_2:
        "Oferecemos serviços para passageiros individuais e grupos de lazer, além de serviços especializados para grupos de eventos corporativos, viagens de incentivo, produção de feiras e estandes.",
      home_desciption_3:
        "Nossa equipe altamente especializada está preparada para garantir o sucesso e a satisfação em todas as fases do seu evento ou viagem, desde o planejamento até a execução, proporcionando experiências únicas e memoráveis para nossos clientes.",
      about_us_text_1:
        "Fundada em 2002, a Bramer Tours iniciou suas atividades com turismo receptivo nos Estados Unidos. Com o passar do tempo, expandimos, evoluindo para uma operadora de turismo e eventos. Em 2018, conquistamos a nossa própria frota.",
      about_us_text_2:
        "Com 24 anos de experiência no Mercado Brasileiro, América Latina, México e Europa, demonstramos nosso compromisso e expertise em fornecer serviços de alta qualidade, encantando clientes em diferentes regiões do mundo.",
      about_us_text_3:
        "Nossa equipe está sempre em busca de inovação e dos melhores recursos disponíveis, colaborando com nossos parceiros para garantir excelência e qualidade em todos os aspectos.",
      service_1_title: "Operadora de Turismo e Grupos de Lazer",
      service_1_description:
        "Oferecemos uma gama de serviços durante as viagens de passageiros individuais (FIT) e grupos de lazer. Desde receptivos, traslados, hospedagem, passeios até coordenação de atividades locais, garantindo uma experiência completa e personalizada para cada cliente.",
      service_2_title: "Eventos Corporativos e Viagens de Incentivo",
      service_2_description:
        "Especializada em planejamento e execução de eventos corporativos e viagens de incentivo sob medida. Nossa equipe desenvolve roteiros exclusivos que incluem uma variedade de atividades, restaurantes e experiências locais em todo o território americano. Desde reuniões estratégicas até eventos de team building e momentos de lazer, cuidamos de todos os detalhes para garantir uma experiência memorável e impactante para nossos clientes e seus colaboradores.",
      service_3_title: "Feiras, Congressos e Estandes",
      service_3_description:
        "A equipe de produção da Bramer Tours USA oferece um serviço completo para feiras, congressos e estandes. Desde o planejamento inicial até o suporte durante o evento, eles coordenam todos os aspectos, incluindo cenografia, montagem, gerenciamento de fornecedores e gestão de brindes. Seu objetivo é garantir que a participação do cliente seja um sucesso, proporcionando uma experiência memorável para os visitantes.",
      service_1_subtitle_1: "Las Vegas",
      service_1_subtitle_2: "Los Angeles",
      service_1_subtitle_3: "San Francisco",
      service_1_subtitle_1_description:
        "Las Vegas é conhecida como a cidade do entretenimento, com uma infinidade de opções emocionantes para os visitantes. Aqui está uma visão geral dos principais pontos turísticos e serviços disponíveis em Las Vegas",
      service_1_subtitle_1_description_1:
        "Transfer Aeroporto/Hotel/Aeroporto: Oferecemos serviços de traslado seguro e confortável entre o aeroporto e o hotel, garantindo uma chegada e partida tranquila para os visitantes.",
      service_1_subtitle_1_description_2:
        "Tour de Compras: Explore os diversos shoppings e outlets de Las Vegas, onde você encontrará uma ampla variedade de lojas de marcas renomadas com descontos incríveis.",
      service_1_subtitle_1_description_3:
        'City Tour "Night Las Vegas": Descubra a deslumbrante Las Vegas à noite, com seus famosos letreiros luminosos, fontes dançantes e uma atmosfera vibrante que transforma a cidade em um espetáculo de luzes e cores.',
      service_1_subtitle_1_description_4:
        '**Grand Canyon "West Rim":** Faça uma excursão emocionante ao Grand Canyon, uma das maravilhas naturais mais impressionantes do mundo, e desfrute de vistas panorâmicas deslumbrantes, caminhadas e atividades opcionais como passeios de helicóptero sobre o desfiladeiro.',
      service_1_subtitle_1_description_5:
        "Red Rock Canyon: Explore a beleza natural do Red Rock Canyon, com suas formações rochosas impressionantes, trilhas para caminhadas e vistas de tirar o fôlego, tudo a uma curta distância de Las Vegas.",
      service_1_subtitle_1_description_6:
        "Hoover Dam: Visite a icônica Hoover Dam, uma das maiores estruturas de engenharia do mundo, e aprenda sobre sua história fascinante em um passeio guiado.",
      service_1_subtitle_1_description_7:
        "Passeios de Helicóptero: Experimente Las Vegas de uma perspectiva única com um emocionante passeio de helicóptero sobre a Strip, o Grand Canyon ou outras atrações próximas.",
      service_1_subtitle_1_description_8:
        "Rota 66: Faça uma viagem nostálgica ao longo da histórica Rota 66, explorando suas cidades pitorescas, pontos de interesse e restaurantes clássicos.",
      service_1_subtitle_1_description_9:
        "Mount Charleston: Fuja do calor do deserto e desfrute de um dia relaxante nas montanhas de Mount Charleston, com trilhas para caminhadas, áreas para piquenique e vistas panorâmicas incríveis.",
      service_1_subtitle_1_description_10:
        "Cirque du Soleil: Assista a um espetáculo inesquecível do Cirque du Soleil, conhecido por suas performances acrobáticas impressionantes, coreografias elaboradas e produções visualmente deslumbrantes.",
      service_1_subtitle_1_description_11:
        "Sphere: Experimente a mais recente atração de entretenimento de Las Vegas, o Sphere, um local inovador que oferece experiências imersivas de última geração, shows de música ao vivo, eventos especiais e muito mais.",
      service_1_subtitle_1_description_12:
        "Outros: Além disso, oferecemos uma variedade de outras atividades e excursões personalizadas para atender aos interesses e preferências individuais dos visitantes, incluindo jantares gourmet, experiências de jogo exclusivas, passeios de aventura e muito mais.",
      service_1_subtitle_2_description:
        'Los Angeles, conhecida como a "Cidade dos Anjos", é uma metrópole diversificada e vibrante, repleta de atrações emocionantes para os visitantes. Aqui está uma visão geral dos principais pontos turísticos e serviços disponíveis em Los Angeles',
      service_1_subtitle_2_description_1:
        "Transfer Aeroporto/Hotel/Aeroporto: Oferecemos serviços de traslado conveniente e confiável entre o aeroporto e o hotel, garantindo uma chegada e partida suaves para os visitantes.",
      service_1_subtitle_2_description_2:
        "Tour de Compras: Explore os famosos shoppings e butiques de Los Angeles, onde você encontrará uma ampla seleção de lojas de grife, boutiques exclusivas e mercados de rua únicos.",
      service_1_subtitle_2_description_3:
        "City Tour Los Angeles e Hollywood: Descubra os pontos turísticos icônicos de Los Angeles e Hollywood, incluindo o letreiro de Hollywood, a Calçada da Fama, o Teatro Chinês TCL, o Píer de Santa Monica e muito mais.",
      service_1_subtitle_2_description_4:
        "Beach Tour Santa Monica: Desfrute de um dia relaxante na deslumbrante praia de Santa Monica, onde você pode passear pelo famoso Píer de Santa Monica, fazer compras na Third Street Promenade ou simplesmente relaxar ao sol.",
      service_1_subtitle_2_description_5:
        "Passeio Santa Barbara: Faça uma viagem cênica até a encantadora cidade costeira de Santa Barbara, conhecida por suas praias de areia branca, arquitetura espanhola e vinhedos pitorescos.",
      service_1_subtitle_2_description_6:
        "Traslado para Parques Disney, Universal e Warner: Oferecemos transporte conveniente para os parques temáticos mais populares da região, incluindo a Disneyland, Universal Studios Hollywood e Warner Bros. Studio Tour.",
      service_1_subtitle_2_description_7:
        "**Outros:** Além disso, podemos organizar uma variedade de outras atividades e excursões personalizadas, como visitas a estúdios de cinema, degustações de vinhos em vinícolas locais, passeios de bicicleta pela cidade e muito mais.",
      service_1_subtitle_3_description:
        "San Francisco, conhecida por sua atmosfera única, paisagens deslumbrantes e cultura diversificada, é uma cidade que oferece uma ampla variedade de experiências para os visitantes. Aqui está uma visão geral dos principais pontos turísticos e serviços disponíveis em San Francisco",
      service_1_subtitle_3_description_1:
        "Transfer Aeroporto/Hotel/Aeroporto: Oferecemos serviços de traslado confiáveis e confortáveis entre o aeroporto e o hotel, garantindo uma chegada e partida sem estresse para os visitantes.",
      service_1_subtitle_3_description_2:
        "Tour de Compras: Explore as diversas opções de compras em San Francisco, incluindo boutiques exclusivas, lojas de grife e mercados de rua pitorescos.",
      service_1_subtitle_3_description_3:
        "City Tour \"Union Square e Fisherman's Wharf\": Descubra os pontos turísticos mais famosos de San Francisco, como a praça Union Square, conhecida por suas lojas de luxo, e o Fisherman's Wharf, com sua atmosfera animada e restaurantes de frutos do mar frescos.",
      service_1_subtitle_3_description_4:
        'City Tour "Golden Gate Bridge e Sausalito": Maravilhe-se com a icônica Golden Gate Bridge e desfrute de vistas panorâmicas espetaculares da cidade. Em seguida, faça uma visita à encantadora cidade costeira de Sausalito, com suas galerias de arte, lojas encantadoras e restaurantes à beira-mar.',
      service_1_subtitle_3_description_5:
        "Alcatraz: Faça uma excursão à famosa prisão de Alcatraz, situada em uma ilha na baía de San Francisco. Explore as celas históricas, aprenda sobre a fascinante história da prisão e desfrute das vistas deslumbrantes da cidade.",
      service_1_subtitle_3_description_6:
        "Napa Valley: Desfrute de uma viagem relaxante ao Vale do Napa, uma das regiões vinícolas mais famosas do mundo. Faça degustações de vinhos em vinícolas premiadas, explore belos vinhedos e desfrute de refeições gourmet em restaurantes locais.",
      service_1_subtitle_3_description_7:
        "**Outros:** Além disso, podemos organizar uma variedade de outras atividades e excursões personalizadas, como passeios de bicicleta pela Ponte Golden Gate, visitas a museus de arte e ciência, cruzeiros pela baía de San Francisco e muito mais.",
      service_2_subtitle_1: "Eventos Corporativos ",
      service_2_subtitle_1_description_1:
        "Com uma abordagem abrangente e focada no cliente, garantimos que cada evento corporativo seja um sucesso, deixando uma impressão duradoura nos participantes e alcançando os objetivos comerciais da empresa.",
      service_2_subtitle_1_description_2:
        "Cuidamos de todos os aspectos logísticos, como reserva de locais, transporte, hospedagem e catering, para garantir uma experiência tranquila para os participantes.",
      service_2_subtitle_2: "Viagens de Incentivo",
      service_2_subtitle_2_description_1:
        "Criamos viagens sob medida, levando em consideração os interesses, objetivos e orçamento de cada cliente.",
      service_2_subtitle_2_description_2:
        "Oferecemos acesso a destinos exclusivos e experiências autênticas que vão além do turismo convencional, proporcionando momentos memoráveis para os participantes.",
      service_3_subtitle_1: "Produção",
      service_3_subtitle_1_description:
        "Bramer Tours USA conta com uma equipe de produção que oferece soluções impecáveis para estandes e exposições em feiras comerciais, garantindo que cada detalhe seja meticulosamente gerenciado. Desde o conceito até a execução, garantimos uma presença marcante que cativa o público.",
      service_3_subtitle_2: "Coordenação",
      service_3_subtitle_2_description:
        "Eleva o seu próximo congresso ou feira comercial com os serviços de coordenação especializados da Bramer Tours USA. Nossa equipe dedicada garante um planejamento e execução perfeitos para o seu congresso ou feira comercial, garantindo uma experiência marcante que supera as expectativas e deixa uma impressão duradoura.",
      contact_us_title:
        "Estamos ansiosos para ouvir de você! Entre em contato conosco para discutir como podemos atender às suas necessidades. Estamos aqui para oferecer soluções excepcionais e personalizadas para você. Aguardamos o seu contato!",
      contact_us_label_full_name: "Nome completo",
      contact_us_label_email: "E-mail",
      contact_us_label_phone: "Telefone",
      contact_us_label_company: "Empresa",
      contact_us_label_subject: "Assunto",
      contact_us_label_subject_selector_single: "Individual (FIT)",
      contact_us_label_subject_selector_group: "Grupo de Lazer",
      contact_us_label_subject_selector_event: "Evento Corporativo",
      contact_us_label_subject_selector_travel: "Viagem de Incentivo",
      contact_us_label_subject_selector_marketing:
        "Feiras, Congressos e Estande",
      contact_us_label_message: "Messagem",
      contact_us_label_message_agreement:
        "Eu concordo em fornecer meus dados pessoais (nome, e-mail, telefone, etc.) exclusivamente para fins de contato e comunicação relacionada aos serviços oferecidos. Entendo que meus dados não serão compartilhados com terceiros e serão utilizados apenas conforme necessário para essa finalidade específica.",
      contact_us_button_submit: "Enviar",
      contact_us_error_full_name: "Por favor preencha seu nome completo",
      contact_us_error_email: "Por favor inserir o seu endereço de email",
      contact_us_error_company: "Por favor inserir o nome da Empresa",
      contact_us_error_subject: "Por favor selecione o tema de interesse",
      contact_us_error_message: "Por favor escreva sua mensagem",
      contact_us_error_agree:
        "Você deve aceitar os termos para envio da mensagem",
        contact_message_successful: "Mensagem enviada com sucesso",
        contact_message_successful_error: "Ocorreu um erro. Por favor, tente novamente mais tarde",
      privacy_policy: "Política de Privacidade",
      privacy_policy_description:
        "Esta Política de Privacidade descreve como coletamos, usamos e compartilhamos suas informações pessoais quando você utiliza nossos serviços. Ao utilizar nossos serviços, você concorda com a coleta e uso de suas informações de acordo com esta política.",
      privacy_policy_information_collection: "Coleta de Informações",
      privacy_policy_information_collection_description:
        "Podemos coletar informações pessoais que você nos fornece diretamente ao utilizar nossos serviços, como seu nome, endereço de e-mail, número de telefone e informações de pagamento.",
      privacy_policy_use_information: "Uso de Informações",
      privacy_policy_use_information_description:
        "Utilizamos suas informações pessoais para fornecer, manter e melhorar nossos serviços, para processar suas transações, responder a solicitações de suporte e para enviar comunicações relacionadas aos nossos serviços.",
      privacy_policy_information_sharing: "Compartilhamento de Informações",
      privacy_policy_information_sharing_description:
        "Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer nossos serviços, atender às obrigações legais ou proteger nossos direitos.",
      privacy_policy_security: "Segurança",
      privacy_policy_security_description:
        "Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição.",
      privacy_policy_changes_to_this_policy: "Alterações nesta Política",
      privacy_policy_changes_to_this_policy_description:
        "Esta Política de Privacidade está sujeita a alterações. Quaisquer alterações serão publicadas nesta página e entrarão em vigor imediatamente após a sua publicação.",
      privacy_policy_contact_us: "Entre em Contato",
      privacy_policy_doubt:
        "Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco.",
      privacy_policy_last_update:
        "Esta Política de Privacidade foi atualizada em 04/13/24.",
    },
  },
};

// Check the localstorage before i18next can
const storedLang = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    storedLang,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
