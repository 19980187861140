import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { images } from "../../constants";
import { Box } from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ServiceCard = ({ urlImage, title, description, children }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [expandedInternal, setExpandedInternal] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandInternalClick = () => {
    setExpandedInternal(!expandedInternal);
  };

  const onMouseOver = () => setExpanded(true);
  const onMouseOut = () => setExpanded(false);

  return (
    <div style={{ margin: "5%" }}>
      <Card
        sx={{ maxWidth: 385, elevation: 0, marginBottom: 10 }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CardHeader title={title} subheader="" />
        </Box>

        <CardContent sx={{ elevation: 0 }}>
          <Box
            bgcolor="black"
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              className="img-fluid"
              src={images[urlImage]}
              alt="design"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{description}</Typography>
          </CardContent>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expandedInternal}
              onClick={handleExpandInternalClick}
              aria-expanded={expandedInternal}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expandedInternal} timeout="auto" unmountOnExit>
            <CardContent>{children}</CardContent>
          </Collapse>
        </Collapse>
      </Card>
    </div>
  );
};
export default ServiceCard;
